export const UTILITY_BILLS_API_PATHS = {
  CHANGE_FORMAT: (id: number) =>  `/collector/${id}/formats`,
  SEARCH_FILTER: "/users/searchfilters",
  UTILITY_BILLS: "/utility-bills",
  UTILITY_BILL: (utilityBillId: string) => `/utility-bills/${utilityBillId}`,
  UTILITY_BILL_RECALCULATE: "/utility-bills/recalculate",
  EXPORT_UTILITY_BILLS: "/export/bills",
  APPROVE_UTILITY_BILL: (utilityBillId: string) =>
    `/utility-bills/${utilityBillId}/approved`,
  DECLINE_UTILITY_BILL: (utilityBillId: string) =>
    `/utility-bills/${utilityBillId}/declined`,
  MANUAL_APPROVE_UTILITY_BILL: (utilityBillId: string) =>
    `/utility-bills/${utilityBillId}/manual_approve`,
  UPLOAD_HISTORICAL_UTILITY_BILLS: "/upload",
  UTILITY_BILLS_YEARS: "/utility-bills/years",
  UTILITY_BILLSCSV: "/utility-bills/export",
  GET_DUPLICATE_DATA:"/utility-bills/duplicate",
  UTILITY_BILLS_FEEDBACK: "/utility-bills/feedback"
};
