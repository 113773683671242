import { Box } from "@mui/material";
import React, { FunctionComponent, memo } from "react";

import { EMPTY_CELL_VALUE, MEASURE_UNIT_DISPLAY_MAP } from "components";

import { MaybeNull } from "types";

import { isNullOrUndefined, toUSNumber } from "utils";

import { EmissionCellTooltip } from "./emission-cell-tooltip";

export const EmissionCell: FunctionComponent<{
  value?: MaybeNull<string>;
  explanation?: MaybeNull<string>;
  unit?: MaybeNull<string>;
  unitvalueshow?: MaybeNull<boolean>
}> = memo(({ value, explanation, unit, unitvalueshow }) => (
  <>
    {!isNullOrUndefined(value) ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {unitvalueshow ?
          <div>{toUSNumber(value!)} {unit}</div>
          :
          <div>
            {toUSNumber(value!)} t {MEASURE_UNIT_DISPLAY_MAP.co2}
          </div>}
        <Box sx={{ mt: -1 }} >
          <EmissionCellTooltip explanation={explanation} />
        </Box>
      </Box>
    ) : (
      EMPTY_CELL_VALUE
    )}
  </>
));
