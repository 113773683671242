import { Box } from "@mui/material";
import MaterialReactTable, {
  MRT_RowSelectionState,
  MRT_SortingState,
} from "material-react-table";
import React, { useContext } from "react";

import {
  DocumentsUpload,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
} from "components";

import { useDocumentScope } from "hooks";

import { Scope3Context } from "pages/authenticated/router";

import { checkIsScope3 } from "utils";

import { Actions } from "./components/actions";
import { SCOPE_DOCUMENTS_COLUMNS } from "./components/table/columns";

export const DocumentUploadPageScope3 = () => {
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);

  const { activeScope } = useContext(Scope3Context);
  const isScope3 = checkIsScope3(activeScope);

  const handleRowSelection = () => {
    setRowSelection({});
  };

  const columns = SCOPE_DOCUMENTS_COLUMNS;

  const { data, isLoading } = useDocumentScope({
    isScope3,
  });
  const tableRows = data?.documentsScope3 || [];

  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box
          sx={{
            pt: 2,
          }}
        >
          <DocumentsUpload isScope3 />
        </Box>
        <Box sx={FLEX_ALIGN_CENTER_GAP_2} />

        <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
          <Actions
            rowSelection={rowSelection}
            setrowSelection={handleRowSelection}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <MaterialReactTable
          columns={columns}
          muiTableContainerProps={{
            sx: {
              "& thead tr:first-of-type th:first-of-type": {
                paddingLeft: "20px",
              },
              "& tbody tr td:first-of-type": {
                paddingLeft: "20px",
              },
              "& tbody tr:first-of-type td > p": {
                display: "flex",
                width: "100%",
                justifyContent: "center",
              },
              "& tbody tr td:nth-child(2)": {
                display: "table-row",
                paddingRight: "40px",
                lineHeight: 2,
              },
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              "@media (min-width: 600px)": {
                width: "100%",
              },
              "@media (min-width: 960px)": {
                width: "1100px",
              },
              "@media (min-width: 1280px)": {
                width: "1100px",
              },
              "@media (min-width: 1440px)": {
                width: "1100px",
              },
              "@media (min-width: 1680px)": {
                width: "1300px",
              },
              "@media (min-width: 2400px)": {
                width: "100%",
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: "10px",
              boxShadow: "none",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: "30px",
              "& .MuiBadge-root": {
                display: "none",
              },
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              "& > th": {
                paddingTop: 1,
                paddingBottom: 1,
              },
              height: "45px",
              backgroundColor: "#F8FAFC",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              height: "45px",
              backgroundColor: "white",
            },
          }}
          data={tableRows}
          enableRowSelection
          enablePagination={false}
          getRowId={(row) => row.id}
          enableColumnResizing
          enableDensityToggle={false}
          enableStickyHeader
          initialState={{ density: "compact" }}
          onSortingChange={setSorting}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection, isLoading, sorting }}
          enableRowVirtualization
        />
      )}
    </MarginWrapper>
  );
};
