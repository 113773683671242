import { Button, ButtonProps } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { ChangeEvent, FunctionComponent } from "react";

import { useNotify } from "hooks";

import { UserRole, useUserRole } from "recoils";
import { EsgFolderUpload } from "assets";
import { toast } from "react-toastify";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const RecUploadButton: FunctionComponent<{
  label: string;
  uploadApiCall: ({
    file,
    isScope3,
  }: {
    file: FormData;
    isScope3: Boolean;
  }) => Promise<AxiosResponse>;
  fileType?: string;
  accept?: string;
  queryKeyToInvalidate: string;
  availableToRoles: UserRole[];
  extraReqParams?: [string, string][];
  variant?: ButtonProps["variant"];
  isScope3?: Boolean;
  marginTop?: string;
}> = ({
  uploadApiCall,
  fileType = EXCEL_FILE_TYPE,
  accept = `${EXCEL_FILE_TYPE}, application/vnd.ms-excel`,
  queryKeyToInvalidate,
  availableToRoles,
  label,
  extraReqParams,
  variant = "contained",
  isScope3 = false,
  marginTop = "10px",
}) => {
  const notify = useNotify();
  const userRole = useUserRole();
  const queryClient = useQueryClient();
  const { mutateAsync: upload, isLoading } = useMutation(uploadApiCall);

  // const onChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { files } = e.target;
  //   const formData = new FormData();

  //   if (files) {
  //     formData.append(
  //       "file",
  //       new Blob([files[0]], {
  //         type: fileType,
  //       }),
  //       files[0].name,
  //     );
  //     if (extraReqParams) {
  //       extraReqParams.forEach(([key, value]) => {
  //         formData.append(key, value);
  //       });
  //     }
  //     upload(
  //       { file: formData, isScope3 },
  //       {
  //         onSuccess: (data) => {
  //           if (data?.data?.message!) {
  //             notify.success(data?.data?.message);
  //           } 
  //           queryClient.invalidateQueries([queryKeyToInvalidate]);
  //         },
  //         onError: (error) => {
  //           notify.error(
  //             `Failed to upload file: ${files[0].name}! (${
  //               (error as AxiosError)?.response?.data || "Internal Server Error"
  //             })`,
  //           );
  //         },
  //         onSettled() {
  //           e.target.value = "";
  //         },
  //       },
  //     )
  //   }
  // };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const formData = new FormData();

    if (files) {
      formData.append(
        "file",
        new Blob([files[0]], {
          type: fileType,
        }),
        files[0].name,
      );

      if (extraReqParams) {
        extraReqParams.forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      try {
        notify.success('Your file upload was successful. Expect a notification in the top right bell once the data is available in ESG Flo.');
        // toast.success('Your file upload was successful. Expect a notification once the parameter-based estimates (OR onboarding sheet OR renewable energy certificate) process concludes.', {
        //   position: "top-center",
        //   // autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   closeButton: true,
        
        //   style: {
        //     backgroundColor: 'green', 
        //     color: 'white', 
        //     height: '50px', 
        //     width: '1050px',
        //     fontSize:"14px",
        //     fontFamily:"manrope",
        //   },
        //   icon: false,
        //   });
          const response = await upload({ file: formData, isScope3 })

          if (response?.data?.message) {
            // notify.success(response.data.message);

            setTimeout(() => {
              toast.dismiss();
            }, 3000);
            // toast.dismiss();
          }
        queryClient.invalidateQueries([queryKeyToInvalidate]);
      } catch (error) {
        notify.error(
          `Failed to upload file: ${files[0].name}! (${
            (error as AxiosError)?.response?.data || "Internal Server Error"
          })`,
        );

        setTimeout(() => {
          toast.dismiss();
        }, 3000);
        // toast.dismiss();
      } finally {
        e.target.value = "";
      }
    }
  };

  return (
    <Button
      sx={ queryKeyToInvalidate === "sites" ? { marginTop, minWidth: "200px", fontWeight: "500", borderRadius: 2, height: "36px", } : { marginTop }}
      variant={variant}
      component="label"
      startIcon={<EsgFolderUpload />}
      disabled={isLoading || !userRole.isRoleIn(availableToRoles)}
    >
      {label}
      <input hidden accept={accept} type="file" onChange={onChange} />
    </Button>
    
  );
};

