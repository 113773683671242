import React, { useState, ChangeEvent, FunctionComponent } from "react";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useNotify } from "hooks";
import { UserRole, useUserRole } from "recoils";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box } from "@mui/system";

const EXCEL_FILE_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const UploadOverRideButton: FunctionComponent<{
  label: string;
  uploadApiCall: ({ file, isScope3, overrideFlag }: { file: FormData; isScope3: boolean; overrideFlag: boolean }) => Promise<AxiosResponse>;
  accept?: string;
  queryKeyToInvalidate: string;
  availableToRoles: UserRole[];
  extraReqParams?: [string, string][];
  variant?: ButtonProps["variant"];
  isScope3?: boolean;
  marginTop?: string;
  apiResponse?: any;
  uploaddiseble?: boolean;
  setUploadsdiseble?: any;
}> = ({
  uploadApiCall,
  accept = `${EXCEL_FILE_TYPE}, application/vnd.ms-excel`,
  queryKeyToInvalidate,
  availableToRoles,
  label,
  extraReqParams,
  variant = "contained",
  isScope3 = false,
  marginTop = "10px",
  apiResponse,
  uploaddiseble,
  setUploadsdiseble,
}) => {
    const notify = useNotify();
    const userRole = useUserRole();
    const queryClient = useQueryClient();
    const { mutateAsync: upload, isLoading } = useMutation(uploadApiCall);
    const [openFirstDialog, setOpenFirstDialog] = useState(false);
    const [openSecondDialog, setOpenSecondDialog] = useState(false);
    const [overrideFlag, setOverrideFlag] = useState(false);

  const handleFirstDialogClose = (choice: string) => {
    setOpenFirstDialog(false);
    if (choice === "Yes") {
      setOpenSecondDialog(true);
      setOverrideFlag(true);
    }
    if (choice === "Not Sure" || choice === "No") {
      document.getElementById("file-input")?.click();
      setOverrideFlag(false);
    }
  };

  const handleSecondDialogClose = (choice: string) => {
    setOpenSecondDialog(false);
    setOverrideFlag(false);
    if (choice === "Yes") {
      document.getElementById("file-input")?.click();
      setOverrideFlag(true);
    }
    if (choice === "No") {
      document.getElementById("file-input")?.click();
      setOverrideFlag(false);
    }
  };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const formData = new FormData();

      if (files) {
        formData.append("file", files[0], files[0].name);
        formData.append("overrideFlag", overrideFlag as any);
        if (extraReqParams) {
          extraReqParams.forEach(([key, value]) => {
            formData.append(key, value);
          });
        }
        setUploadsdiseble(true);
        upload(
          { file: formData, 
            isScope3,
            overrideFlag
           },
          {
            onSuccess: (data) => {
              apiResponse(true);
              setTimeout(() => apiResponse(false), 2000);

              if (data?.data?.message === "Records have been inserted !!! ") {
                notify.success(`Successfully uploaded file ${files[0].name}!`);
              } else {
                notify.success("Duplicate data entries were identified and the most recently uploaded data is being used");
              }

              queryClient.invalidateQueries([queryKeyToInvalidate]);
            },
            onError: (error) => {
              notify.error(`Failed to upload file: ${files[0].name}! (${(error as AxiosError)?.response?.data || "Internal Server Error"})`);
            },
            onSettled() {
              e.target.value = "";
            },
          },
        );
      }
    };

    return (
      <>
        {/* Main Upload Button */}
        <Button
          sx={{ marginTop }}
          variant={variant}
          component="label"
          startIcon={<GridOnIcon />}
          disabled={isLoading || !userRole.isRoleIn(availableToRoles) || uploaddiseble}
          onClick={() => setOpenFirstDialog(true)}
        >
          {label}
        </Button>
        <input id="file-input" hidden accept={accept} type="file" onChange={onChange} />

        {/* First Dialog */}
        <Dialog
          open={openFirstDialog}
          onClose={() => handleFirstDialogClose("No")}
          PaperProps={{
            sx: {
              width: 384,
              bgcolor: "#FFFFFF",
            },
          }}
        >
          <DialogContent sx={{py: '2px', pl: 3}}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlineIcon  sx={{color:"#C55050"}}/>
              <DialogTitle sx={{ px: '10px', fontSize: '16px', fontWeight: 600, fontFamily: 'Inter', m: 0 }}>
                Data Points Confirmed?
              </DialogTitle>
            </Box>
            <DialogContentText sx={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Inter', opacity: 0.65 }}>
              Are you uploading confirmed data points (e.g., historically verified data, data from metering systems)?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
              onClick={() => handleFirstDialogClose("Not Sure")}
              variant="text"
            >
              Not Sure
            </Button>
            <Button
              sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
              onClick={() => handleFirstDialogClose("No")}
              variant="text"
            >
              No
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                background: "#C55050",
                color: "white",
                fontSize: '14px',
                fontWeight: 600,
                "&:hover": { background: "#C55050" },
              }}
              onClick={() => handleFirstDialogClose("Yes")}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>


        {/* Second Dialog */}
        <Dialog
          open={openSecondDialog} onClose={() => handleSecondDialogClose("No")}
          PaperProps={{
            sx: {
              width: 384,
              bgcolor: "#FFFFFF",
            },
          }}
        >
          <DialogContent sx={{py: '2px', pl: 3}}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlineIcon  sx={{color:"#C55050"}}/>
              <DialogTitle sx={{ px: '10px', fontSize: '16px', fontWeight: 600, fontFamily: 'Inter', m: 0 }}>
                Override All Flags?
              </DialogTitle>
            </Box>
            <DialogContentText sx={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Inter', opacity: 0.65 }}>
            Do you want to override all anomaly flags? (e.g. Duplicate detection, YoY/MoM threshold detection, etc.)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
              onClick={() => handleSecondDialogClose("No")}
              variant="text"
            >
              No
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                background: "#C55050",
                color: "white",
                fontSize: '14px',
                fontWeight: 600,
                "&:hover": { background: "#C55050" },
              }}
              onClick={() => handleSecondDialogClose("Yes")}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
