import { MaybeNull } from "types";

import { getResData, httpClient } from "utils";

export interface UserDTO {
  active: boolean;
  admin: boolean;
  auditor: boolean;
  email: string;
  firstName: MaybeNull<string>;
  id: number;
  lastName: MaybeNull<string>;
  oktaIdentifier: string;
  sites: number[];
  enabled?: boolean | undefined;
  dateFormat?: string;
}

export type CreateUserDTO = Omit<UserDTO, "active" | "id" | "sites"> & {
  siteIds: number[];
};

export type UpdateUserDTO = Pick<
  UserDTO,
  "email" | "firstName" | "id" | "lastName"
>;

export type UpdateUserRoleDTO = Pick<UserDTO, "admin" | "auditor" | "id"> & {
  siteIds: number[];
};

export const USERS_API_PATHS = {
  AUTHENTICATED_USER: "/users/logged",
  USERS: "/users",
  USER: (userId: number) => `/users/${userId}`,
  DELETE_USER: (userId: number) => `/users/${userId}`,
  RE_ACTIVATE_USERS: (userId: number) => `/users/reactivate?ids=${userId}`,
  USERS_ROLES: "/users/roles",
  USERS_SITES: (userId: number, siteId: number) => `/users/${userId}/sites/${siteId}`,
  USERS_BY_SITE_ID: (siteId: number) => `/users/${siteId}/tagging`,
};

export const createUser = (body: CreateUserDTO) =>
  httpClient.post(USERS_API_PATHS.USERS, body);

export const getUsers = (): Promise<UserDTO[]> =>
  httpClient.get<UserDTO[]>(USERS_API_PATHS.USERS).then(getResData);

export const getUsersBySiteId = (siteId: number): Promise<UserDTO[]> =>
  httpClient
    .get<UserDTO[]>(USERS_API_PATHS.USERS_BY_SITE_ID(siteId))
    .then(getResData);

export const getUser = (userId: number): Promise<UserDTO> =>
  httpClient.get<UserDTO>(USERS_API_PATHS.USER(userId)).then(getResData);

export const getAuthenticatedUser = (): Promise<UserDTO> =>
  httpClient.get<UserDTO>(USERS_API_PATHS.AUTHENTICATED_USER).then(getResData);

export const updateUser = (body: UpdateUserDTO) =>
  httpClient.put(USERS_API_PATHS.USERS, body);

export const updateUserRole = (body: UpdateUserRoleDTO) =>
  httpClient.put(USERS_API_PATHS.USERS_ROLES, body);

export const updateUserSite = (userId: number, siteId: number) =>
  httpClient.put(USERS_API_PATHS.USERS_SITES(userId, siteId));

export const deleteUserSite = (userId: number, siteId: number) =>
  httpClient.delete(USERS_API_PATHS.USERS_SITES(userId, siteId));

export const deleteUser = (userId: number) =>
  httpClient.delete(USERS_API_PATHS.DELETE_USER(userId));

export const reActivateUser = (userId: number) =>
  httpClient.put(USERS_API_PATHS.RE_ACTIVATE_USERS(userId));
