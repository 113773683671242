import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { styled } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { SelectControlledField } from "components/inputs/select-field";
import { COLORS } from "components";

const PageDiv = styled("div")(() => ({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  margin: "15px",
  padding: "50px",
}));

const LabelDiv = styled("h3")(() => ({
  fontWeight: "600",
  lineHeight: "28px",
  fontSize: "16px",
  color: COLORS.lightgray,
}));

type ChartWrapperProps = {
  children?: ReactNode;
  // renderHamIcon?: boolean;
  renderExpandIcon?: boolean;
  renderYearDropdown?: boolean;
  // label: string;
  label: ReactNode;
  yearOptions?: string[];
  selectedYear?: string;
  setSelectedYear?: (data: string) => void;
  styles?: object;
  id?: string;
  fullScreenChange?: (val: boolean) => void;
};

const chartStyle = {
  border: `1px solid #054254`,
  borderRadius: '25px'
}

export const ChartWrapper: FunctionComponent<ChartWrapperProps> = ({
  label,
  children = null,
  renderExpandIcon = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderYearDropdown = false,
  yearOptions = [],
  selectedYear = "",
  setSelectedYear = () => {},
  styles = chartStyle,
  id = "",
  fullScreenChange = () => {},
}) => {
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const isFullscreen = useRef(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);

  function fullscreenchanged() {
    if (!document.fullscreenElement) {
      exitFullscreen();
      setFullScreenMode(false);
      isFullscreen.current = false;
    }
  }

  useEffect(() => {
    fullScreenChange(fullScreenMode);
  }, [fullScreenMode, fullScreenChange]);

  const el = document.getElementById(id || "fullscreen-div");
  el?.addEventListener("fullscreenchange", fullscreenchanged);

  const handleExpandClick = () => {
    if (chartWrapperRef.current) {
      if (!isFullscreen.current) {
        enterFullscreen(chartWrapperRef.current);
        isFullscreen.current = true;
        setFullScreenMode(true);
      } else {
        exitFullscreen();
        setFullScreenMode(false);
        isFullscreen.current = false;
      }
    }
  };

  const enterFullscreen = (element: HTMLElement) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    const { document } = window;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  };
  return (
    <PageDiv sx={styles} ref={chartWrapperRef} id={id || "fullscreen-div"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #D3D7D6",
          padding: "5px 5px 5px 15px",
        }}
      >
        <Box>
          <LabelDiv>{label}</LabelDiv>
        </Box>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          {/* {renderYearDropdown && ( */}
          { yearOptions.length !== 0 && (
            <Box
              sx={{
                minWidth: "120px",
                width: "fit-content",
              }}
            >
              <SelectControlledField
                options={yearOptions}
                selectedValues={selectedYear}
                setSelectedValues={setSelectedYear}
              />
            </Box>
          )}
          {renderExpandIcon && (
            <div
              role="button"
              tabIndex={0}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                display: "none",
              }}
              onClick={handleExpandClick}
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" ||
                  event.key === " " ||
                  fullScreenMode
                ) {
                  handleExpandClick();
                }
              }}
            >
              <OpenInFullIcon />
            </div>
          )}
        </Box>
      </Box>
      <Box>{children}</Box>
    </PageDiv>
  );
};
