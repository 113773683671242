import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { UtilityBillByIdDTO } from "api";

import { Loading } from "components";

import { useUtilityBillById } from "hooks";

import { UTILITY_BILL_PAGE } from "../consts";

export const UtilityBillLoader: FunctionComponent<{
  Component: FunctionComponent<{ utilityBill: UtilityBillByIdDTO, isLoading: boolean }>;
}> = ({ Component }) => {
  const { billId } = useParams<{ [UTILITY_BILL_PAGE.KEY]: string }>();
  const { data: utilityBill, isLoading, isFetching } = useUtilityBillById(billId!);

  return isLoading && !utilityBill ? (
    <Loading />
  ) : (
    <Component utilityBill={utilityBill!} isLoading={isLoading || isFetching } />
  );
};
