/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable */
import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useCallback, useEffect, useState } from "react";

import { Body1SemiBold, COLORS, Loading, MultiSelectControlledOption } from "components";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
import {
  EMISSIONS_MONTHLY_HIGHCHARTS_DATA,
  EMISSIONS_SCOPE_YEARLY_HIGH_CHARTS_DATA,
  EMISSIONS_SCOP_MONTHLY_HIGHCHARTS_DATA,
  EMISSIONS_YEARLY_HIGH_CHARTS_DATA,
  EMMISSIONS_BY_COUNTRIES_HIGHCHARTS_DATA,
  EMMISSIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  EMMISSIONS_BY_REGION_HIGHCHARTS_DATA,
  EMMISSIONS_BY_SITE_HIGHCHARTS_DATA,
  EMMISSIONS_BY_UT_HIGHCHARTS_DATA,
  ESTIMATED_EMISSIONS_HIGHTCHARTS_DATA,
  ESTIMATED_SITE_EMMISSIONS_BY_SITE_HIGHCHARTS_DATA1,
} from "../consts";
import {
  findCommonElements,
  generateMonths,
  getRequiredYearDetails,
  parseEmissionsDataMonthly,
  parseEmissionsScopeDataMonthly,
  parseEstimatedSiteEmissionsData,
  parseEstimatesEmissionsDataMonthly,
  parseFTEmissionsData,
  parseRegionEmissionsScopeWise,
  parseScopesByYear,
  parseSitemissionsData,
  parseUtilitiesByYear,
  parseUtilityEmissionsData,
  parseYearEmissionsData,
  parseYearEmissionsScopeData,
} from "../utils";
import { height, styled } from "@mui/system";
import { debounce } from "lodash";

type Props = {
  emissionLoadingStates: any;
  monthEmYears: string[];
  monthEmissionsYear: string;
  regionEmYears: string[];
  regionEmissionsYear: string;
  setMonthEmissionsYear: (data: string) => void;
  setRegionEmissionsYear: (data: string) => void;
  scopeYear: string;
  setScopeYear: (data: string) => void;
  utYear: string;
  setUTYear: (data: string) => void;
  estimatesMonthlyData: unknown;
  estimatesBySiteData: unknown;
  monthlyScopeData: unknown;
  yearlyScopeData: unknown;
  monthlyData: unknown;
  sitesData: unknown;
  yearlyData: unknown;
  regionsData: unknown;
  utilityData: unknown;
  facilityTypesData: unknown;
  scopesResp: unknown;
  countriesResp: unknown;
  sitesOptions: MultiSelectControlledOption[];
  countryRegionsList: string[];
  facilityTypesList: string[];
  utilityTypesOptions: MultiSelectControlledOption[];
  startMonth: string;
  endMonth: string;
  selectedYear: number;
  openAccordian: any;
};

export const OverallEmissionsComponent = ({
  emissionLoadingStates,
  monthlyScopeData,
  yearlyScopeData,
  estimatesBySiteData,
  estimatesMonthlyData,
  monthlyData,
  sitesData,
  yearlyData,
  regionsData,
  utilityData,
  facilityTypesData,
  scopesResp,
  sitesOptions,
  countryRegionsList,
  facilityTypesList,
  utilityTypesOptions,
  monthEmissionsYear,
  setMonthEmissionsYear,
  regionEmissionsYear,
  setRegionEmissionsYear,
  scopeYear,
  setScopeYear,
  utYear,
  setUTYear,
  startMonth,
  endMonth,
  selectedYear,
  openAccordian
}: Props) => {

  // ---- Accordian Component ----

  const Accordion = styled(MuiAccordion)({
    border: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "white",
    "&:before": {
      display: "none",
    },
  });
  
  const CustomAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      overflow: "hidden", // Prevent content overflow
      whiteSpace: "nowrap", // Keep text inline
    },
    "& .MuiIconButton-root": {
      transition: "transform 0.3s",
      transform: "rotate(0deg)",
    },
    "& .Mui-expanded .MuiIconButton-root": {
      transform: "rotate(90deg)",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  // ---- State ----
  const [monthlyEmissionsHighChartsData, setMonthlyEmissionsHighChartsData] = useState(EMISSIONS_MONTHLY_HIGHCHARTS_DATA);
  const [monthlyEmissionsScopHighChartsData, setMonthlyEmissionsScopHighChartsData] = useState(EMISSIONS_SCOP_MONTHLY_HIGHCHARTS_DATA);
  const [siteEmissionsHighChartsData, setSiteEmissionsHighChartsData] = useState(EMMISSIONS_BY_SITE_HIGHCHARTS_DATA);
  const [yearEmissionsData, setYearEmissionsData] = useState(EMISSIONS_YEARLY_HIGH_CHARTS_DATA);
  const [yearEmissionsScopeData, setYearEmissionsScopeData] = useState(EMISSIONS_SCOPE_YEARLY_HIGH_CHARTS_DATA);
  const [estimatedEmissionsHighChartsData, setEstimatedEmissionsHighChartsData] = useState(ESTIMATED_EMISSIONS_HIGHTCHARTS_DATA);
  const [estimatedEmissionSite, setEstimatedEmissionSite] = useState(ESTIMATED_SITE_EMMISSIONS_BY_SITE_HIGHCHARTS_DATA1);
  const [regionsEmissionData, setRegionsEmissionData] = useState(EMMISSIONS_BY_REGION_HIGHCHARTS_DATA);
  const [ftEmissionsData, setFTEmissionsData] = useState(EMMISSIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA);
  const [utEmissionsData, setUTEmissionsData] = useState([{ category: "CO2", values: [] }]);
  const [scopeEmissionsData, setScopeEmissionsData] = useState([{ category: "CO2", values: [] }]);
  const [utEMDataTwoFields, setUTEMDataTwoFields] = useState(EMMISSIONS_BY_UT_HIGHCHARTS_DATA);
  const [countriesEMData, setCountriesEMData] = useState(EMMISSIONS_BY_COUNTRIES_HIGHCHARTS_DATA);
  const [expanded, setExpanded] = React.useState<string | false>("");

  // ---- Page Functions ----
  const updateUtilityMekkoChartData = useCallback(() => {
    if (utilityData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(utilityData),
        utYear.replace("FY", ""),
      );
      const parsedData = parseUtilitiesByYear(
        utilityData,
        reqYear,
        "co2Emission",
      );
      setUTEmissionsData(parsedData);
      if (updateYear) {
        setUTYear(`FY${reqYear}`);
      }
    }
  }, [utilityData, utYear]);

  const dataCheckValue = (dataRes: any) => {
    const hasNonZeroValue = dataRes?.series?.some((item: any) => {
      if (Array.isArray(item?.data)) {
        return item.data.some((value: any) => value !== 0);
      }
      return false;
    });

    return hasNonZeroValue;
  };

  // ---- Use Effects ----
  useEffect(() => {
    openAccordian(expanded)
  },[expanded])
  
  useEffect(() => {
    if (monthlyData) {
      const parsedData = parseEmissionsDataMonthly(
        monthlyData,
        startMonth,
        endMonth,
        "co2Emission",
      );

      const updatedChartsData = JSON.parse(
        JSON.stringify(EMISSIONS_MONTHLY_HIGHCHARTS_DATA),
      );

      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const formattedMonthHeaders = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      const xAxisData = formattedMonthHeaders;
      updatedChartsData.series = parsedData;
      updatedChartsData.xAxis.categories = xAxisData;
      setMonthlyEmissionsHighChartsData(updatedChartsData);
    }
  }, [monthlyData, monthEmissionsYear]);

  useEffect(() => {
    if (monthlyScopeData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(monthlyScopeData),
        monthEmissionsYear.replace("FY", ""),
      );
      const parseDataEstimate = parseEmissionsScopeDataMonthly(
        monthlyScopeData,
        startMonth,
        endMonth,
      );

      const updatedChartsData1 = JSON.parse(
        JSON.stringify(EMISSIONS_SCOP_MONTHLY_HIGHCHARTS_DATA),
      );

      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const formattedMonthHeaders = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      const xAxisData = formattedMonthHeaders;

      updatedChartsData1.series = parseDataEstimate;
      updatedChartsData1.xAxis.categories = xAxisData;

      setMonthlyEmissionsScopHighChartsData(updatedChartsData1);
    }
  }, [monthlyScopeData, monthEmissionsYear]);

  useEffect(() => {
    if (estimatesMonthlyData) {
      const parseDataEstimate = parseEstimatesEmissionsDataMonthly(
        estimatesMonthlyData,
        startMonth,
        endMonth,
        "estimateCo2Emission",
        "co2Emission",
      );

      const updatedChartsData1 = JSON.parse(
        JSON.stringify(ESTIMATED_EMISSIONS_HIGHTCHARTS_DATA),
      );
      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const formattedMonthHeaders = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      const xAxisData = formattedMonthHeaders;
      updatedChartsData1.xAxis.categories = xAxisData;

      updatedChartsData1.series = parseDataEstimate;

      setEstimatedEmissionsHighChartsData(updatedChartsData1);
    }
  }, [estimatesMonthlyData, monthEmissionsYear]);

  useEffect(() => {
    if (scopesResp) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(scopesResp),
        scopeYear.replace("FY", ""),
      );
      const parsedData = parseScopesByYear(scopesResp, reqYear);
      setScopeEmissionsData(parsedData);
      if (updateYear) {
        setScopeYear(`FY${reqYear}`);
      }
    }
  }, [scopesResp, scopeYear]);

  useEffect(() => {
    const filterCategories = utilityTypesOptions.filter(
      (item) =>
        item.label !== "Sewer" && item.label !== "Water" && item.label !== "-",
    );

    if (utilityData) {
      updateUtilityMekkoChartData();

      const utData = parseUtilityEmissionsData(
        utilityData,
        filterCategories,
        "co2Emission",
        startMonth,
        endMonth,
      );
      const updatedTwoFieldChartsData = JSON.parse(
        JSON.stringify(EMMISSIONS_BY_UT_HIGHCHARTS_DATA),
      );

      updatedTwoFieldChartsData.xAxis.categories = filterCategories.map(
        (e) => e.label,
      );
      const indexArrayMain: any[] = [];

      utData.filter((e: any) => {
        const indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key === 0) {
            indexArray.push(index);
          }
        });
        return indexArrayMain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArrayMain);

      updatedTwoFieldChartsData.xAxis.categories = filterCategories.map(
        (e) => e.label,
      );

      updatedTwoFieldChartsData.series = utData;

      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedTwoFieldChartsData.xAxis.categories.splice(Number(element), 1);
        utData.filter((e: any) => e.data.splice(Number(element), 1));
      });

      setUTEMDataTwoFields(updatedTwoFieldChartsData);
    }
  }, [utilityData]);

  useEffect(() => {
    updateUtilityMekkoChartData();
  }, [utYear]);

  useEffect(() => {
    if (sitesData) {
      const parsedData = parseSitemissionsData(
        sitesData,
        sitesOptions,
        "co2Emission",
        startMonth,
        endMonth,
      );

      const modifiedsiteData = parsedData.map((series: any) => {
        return {
          name: `FY${series.name}`,
          data: series.data,
        };
      });


      const updatedChartsData = JSON.parse(
        JSON.stringify(EMMISSIONS_BY_SITE_HIGHCHARTS_DATA),
      );

      const indexArrayMain: any[] = [];

      parsedData.filter((e: any) => {
        const indexArray: any[] = [];

        e.data.forEach((key: any, index: any) => {
          if (key === 0) {
            indexArray.push(index);
          }
        });
        return indexArrayMain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArrayMain);

      
      updatedChartsData.xAxis.categories = sitesOptions.map((e) => e.label);

      const sort = commonElements.sort((a, b) => b - a);

      sort.forEach((element: Number) => {
        updatedChartsData.xAxis.categories.splice(Number(element), 1);
        parsedData.filter((e: any) => e.data.splice(Number(element), 1));
      });

      updatedChartsData.series = modifiedsiteData;

      setSiteEmissionsHighChartsData(updatedChartsData);
    }
  }, [sitesData]);

  useEffect(() => {
    if (estimatesBySiteData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(estimatesBySiteData),
        monthEmissionsYear.replace("FY", ""),
      );

      const parseDataEstimate = parseEstimatedSiteEmissionsData(
        estimatesBySiteData,
        sitesOptions,
        "estimateCo2Emission",
        "co2Emission",
      );

      const updatedChartsData1 = JSON.parse(
        JSON.stringify(ESTIMATED_SITE_EMMISSIONS_BY_SITE_HIGHCHARTS_DATA1),
      );

      const indexArrayMain: any[] = [];

      parseDataEstimate.filter((e: any) => {
        const indexArray: any[] = [];

        e.data.forEach((key: any, index: any) => {
          if (key === 0) {
            indexArray.push(index);
          }
        });
        return indexArrayMain.push(indexArray);
      });
      const commonElements1 = findCommonElements(...indexArrayMain);
      updatedChartsData1.series = parseDataEstimate;
      updatedChartsData1.xAxis.categories = sitesOptions.map((e) => e.label);

      const sort1 = commonElements1.sort((a, b) => b - a);

      sort1.forEach((element: Number) => {
        updatedChartsData1.xAxis.categories.splice(Number(element), 1);
        parseDataEstimate.filter((e: any) => e.data.splice(Number(element), 1));
      });

      setEstimatedEmissionSite(updatedChartsData1);

      if (updateYear) {
        setMonthEmissionsYear(`FY${reqYear}`);
      }
    }
  }, [estimatesBySiteData, monthEmissionsYear]);

  useEffect(() => {
    if (yearlyData) {
      const parsedData = parseYearEmissionsData(
        yearlyData,
        "co2Emission",
        startMonth,
        endMonth,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(EMISSIONS_YEARLY_HIGH_CHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      setYearEmissionsData(updatedChartsData);
    }
  }, [yearlyData]);

  useEffect(() => {
    if (yearlyScopeData) {
      const parsedData = parseYearEmissionsScopeData(
        yearlyScopeData,
        "co2Emission",
        startMonth,
        endMonth,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(EMISSIONS_SCOPE_YEARLY_HIGH_CHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyScopeData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      setYearEmissionsScopeData(updatedChartsData);
    }
  }, [yearlyScopeData]);

  useEffect(() => {
    if (regionsData) {
      const parsedData = parseRegionEmissionsScopeWise(
        regionsData,
        countryRegionsList,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(EMMISSIONS_BY_REGION_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = countryRegionsList;
      updatedChartsData.series = parsedData;

      setRegionsEmissionData(updatedChartsData);
    }
  }, [regionsData, regionEmissionsYear]);

  useEffect(() => {
    if (facilityTypesData) {
      const parsedData = parseFTEmissionsData(
        facilityTypesData,
        facilityTypesList,
        "co2Emission",
        startMonth,
        endMonth,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(EMMISSIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = facilityTypesList;
      const modifiedfTData = parsedData.map((series: any) => {
        return {
          name: `FY${series.name}`,
          data: series.data,
        };
      });

      updatedChartsData.series = modifiedfTData;
      setFTEmissionsData(updatedChartsData);
    }
  }, [facilityTypesData]);

  // ---- Const ----
  const monthlyConsumption = dataCheckValue(monthlyEmissionsHighChartsData);
  const yearEmissionsScope = dataCheckValue(yearEmissionsScopeData);
  const yearEmissions = dataCheckValue(monthlyEmissionsScopHighChartsData);
  const siteEmissionsHigh = dataCheckValue(siteEmissionsHighChartsData);
  const yearEmissionsValue = dataCheckValue(yearEmissionsData);
  const regionsEmission = dataCheckValue(regionsEmissionData);
  const ftEmissions = dataCheckValue(ftEmissionsData);
  const utEMDataTwo = dataCheckValue(utEMDataTwoFields);
  const estimatedEmissions = dataCheckValue(estimatedEmissionsHighChartsData);
  const estimatedEmissionValue = dataCheckValue(estimatedEmissionSite);

  const debouncedSetExpanded = React.useCallback(
    debounce((panel: string | false) => setExpanded(panel), 100),
    []
  );

const handleChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    debouncedSetExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{mx:4}}>

      {/* Time Period */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Time Period</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {expanded === "panel1" &&
            <>
              {/* Emissions Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: monthlyConsumption ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Emissions Month on Month
                  </Typography>
                }
                selectedYear={monthEmissionsYear}
                setSelectedYear={setMonthEmissionsYear}
                id="EmissionsMonth"
              >
                {emissionLoadingStates?.getEmissionsOnMonthlyLoading ? (
                  <Loading />
                ) : monthlyConsumption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyEmissionsHighChartsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>
              {/* Emissions Scope Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: yearEmissions ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Emissions Scope Month on Month
                  </Typography>
                }
                selectedYear={monthEmissionsYear}
                setSelectedYear={setMonthEmissionsYear}
                id="EmissionsMonth Scop"
              >
                {emissionLoadingStates?.getEmissionsScopeLoading ? (
                  <Loading />
                ) : monthlyScopeData ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyEmissionsScopHighChartsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>

                {/* Emissions Year on Year */}
                <Stack spacing={4}>
                <ChartWrapper
                  label={
                    <Typography
                      sx={{
                        color: yearEmissionsValue ? COLORS.lightgray : "#00000042",
                        fontSize: "16px",
                        fontFamily: "Manrope",
                        fontWeight: "600",
                      }}
                    >
                      Emissions Year on Year
                    </Typography>
                  }
                  id="EmissionsYear"
                >
                  {emissionLoadingStates?.getEmissionsYearLoading ? (
                    <Loading />
                  ) : yearEmissionsValue ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={yearEmissionsData}
                    />
                  ) : (
                    <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                      {" "}
                      <Typography
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          pt: "100px",
                          fontSize: "25px",
                        }}
                      >
                        No data available
                      </Typography>
                    </Box>
                  )}
                </ChartWrapper>
              </Stack>

              {/* Emissions Scope Year on Year */}
              <Stack spacing={4}>
                <ChartWrapper
                  label={
                    <Typography
                      sx={{
                        color: yearEmissionsScope ? COLORS.lightgray : "#00000042",
                        fontSize: "16px",
                        fontFamily: "Manrope",
                        fontWeight: "600",
                      }}
                    >
                      Emissions Scope Year on Year
                    </Typography>
                  }
                  id="EmissionsScopeYear"
                >
                  {emissionLoadingStates?.getEmissionsScopeYearLoading ? (
                    <Loading />
                  ) : yearEmissionsScope ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={yearEmissionsScopeData}
                    />
                  ) : (
                    <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                      {" "}
                      <Typography
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          pt: "100px",
                          fontSize: "25px",
                        }}
                      >
                        No data available
                      </Typography>
                    </Box>
                  )}
                </ChartWrapper>
              </Stack>
            
              {/* Estimated Emission Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: estimatedEmissions ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Estimated Emission Month on Month
                  </Typography>
                }
                selectedYear={monthEmissionsYear}
                setSelectedYear={setMonthEmissionsYear}
                id="EstimatedEmission"
              >
                {emissionLoadingStates?.getEstimatesOnMonthlyLoading ? <Loading /> : estimatesMonthlyData ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={estimatedEmissionsHighChartsData}
                  />
                ) : (
                  <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                    {" "}
                    <Typography
                      sx={{
                        textAlign: "center",
                        alignItems: "center",
                        pt: "100px",
                        fontSize: "25px",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Utility Type */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Utility Type</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
        {expanded === "panel2" && 
        <>
            <ChartWrapper
              label={
                <Typography
                  sx={{
                    color: utEMDataTwo ? COLORS.lightgray : "#00000042",
                    fontSize: "16px",
                    fontFamily: "Manrope",
                    fontWeight: "600",
                  }}
                >
                  Emissions by Utility Type
                </Typography>
              }
              id="EmissionsUltra"
            >
              {" "}
              {emissionLoadingStates?.getEmissionsUtilityLoading ? <Loading /> : utEMDataTwo ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={utEMDataTwoFields}
                />
              ) : (
                <Box sx={{ backgroundColor: "#f8f8f8", height: "400px" }}>
                  {" "}
                  <Typography
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                      pt: "130px",
                      fontSize: "25px",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              )}
            </ChartWrapper>
          </>}
        </AccordionDetails>
      </Accordion>

      {/* Site Type */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >

        
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel3" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Site Type</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
        {expanded === "panel3" && 
        <>
          {/* Emissions by Facility type */}
            <ChartWrapper
              label={
                <Typography
                  sx={{
                    color: ftEmissions ? COLORS.lightgray : "#00000042",
                    fontSize: "16px",
                    fontFamily: "Manrope",
                    fontWeight: "600",
                  }}
                >
                  Emissions by Facility type
                </Typography>
              }
            >
              {emissionLoadingStates?.getEmissionsFacilityTypeLoading ? <Loading /> : ftEmissions ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={ftEmissionsData}
                />
              ) : (
                <Box sx={{ backgroundColor: "#f8f8f8", height: "400px" }}>
                  {" "}
                  <Typography
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                      pt: "130px",
                      fontSize: "25px",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              )}
            </ChartWrapper>
          </>}
        </AccordionDetails>
      </Accordion>

      {/* Region / Country */}
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel4" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Region / Country</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {/* Emissions by Region */}
          {expanded === "panel4" &&
            <> <Stack spacing={4}>
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: regionsEmission ? COLORS.lightgray : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600",
                    }}
                  >
                    Emissions by Region
                  </Typography>
                }
                id="EmissionsRegion"
                selectedYear={regionEmissionsYear}
                setSelectedYear={setRegionEmissionsYear}
              >
                {emissionLoadingStates?.getEmissionsScopeLoading ? <Loading /> : regionsEmission ? (
                  <HighchartsReact
                    className="highcharts"
                    highcharts={Highcharts}
                    options={regionsEmissionData}
                  />
                ) : (<Box sx={{ backgroundColor: "#f8f8f8", height: "400px" }}>
                  {" "}
                  <Typography
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                      pt: "130px",
                      fontSize: "25px",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
                )}
              </ChartWrapper>
            </Stack>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Site */}
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
      
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel5" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Site</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {/* Emissions by Site */}
          {expanded === "panel5" && <>
          <ChartWrapper
            label={
              <Typography
                sx={{
                  color: siteEmissionsHigh ? COLORS.lightgray : "#00000042",
                  fontSize: "16px",
                  fontFamily: "Manrope",
                  fontWeight: "600",
                }}
              >
                Emissions by Site
              </Typography>
            }
            id="EmissionsSite">
            {emissionLoadingStates?.getEmissionsSiteLoading ? (
              <Loading />
            ) : siteEmissionsHigh ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={siteEmissionsHighChartsData}
              />
            ) : (
              <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                {" "}
                <Typography
                  sx={{
                    textAlign: "center",
                    alignItems: "center",
                    pt: "100px",
                    fontSize: "25px",
                  }}
                >
                  No data available
                </Typography>
              </Box>
            )}
          </ChartWrapper>
          </>}
        </AccordionDetails>
      </Accordion>

      {/* Estimates */}
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >

        
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel6" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Estimates</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
        {expanded === "panel6" && 
        <>
          {/* Estimated Emission by Site */}
          <ChartWrapper
            label={
              <Typography
                sx={{
                  color: estimatedEmissionValue ? COLORS.lightgray : "#00000042",
                  fontSize: "16px",
                  fontFamily: "Manrope",
                  fontWeight: "600",
                }}
              >
                Estimated Emission by Site
              </Typography>
            }
            selectedYear={monthEmissionsYear}
            setSelectedYear={setMonthEmissionsYear}
            id="EstimatedEmissionSite"
          >
            {emissionLoadingStates?.getEstimatesSiteLoading ? <Loading /> : estimatedEmissionValue ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={estimatedEmissionSite}
              />
            ) : (
              <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}>
                {" "}
                <Typography
                  sx={{
                    textAlign: "center",
                    alignItems: "center",
                    pt: "100px",
                    fontSize: "25px",
                  }}
                >
                  No data available
                </Typography>
              </Box>
            )}
          </ChartWrapper>
          </>}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
