import React, { useState } from 'react'

import { Box } from '@mui/system';
import { DashboardTabs } from '../dashboards/components/dashboardTabs'
import { TabSite } from './tab-site';

export const SitesPageV2 = () => {
  const [value, setValue] = useState<string>("Active"); // eslint-disable-line
  const SITES = "Active";

  const tabs: any = {
    [SITES]: [
      {
        label: "Active",
        content: <TabSite state="active" />,
      },
      {
        label: "Inactive",
        content: <TabSite state="inactive" />,
      },

    ],
  };

  const handleTabChange = (tabName: any) => {
    if (tabName === 0) {
      setValue("Active")
    } else {
      setValue("Inactive")
    };
  }

  return (
    <Box sx={{ ml: 3 }}>
      <DashboardTabs intensity tabs={tabs[SITES]} selectedBtnOption={SITES} onTabChange={handleTabChange} />
    </Box>
  )
}
