/* eslint-disable */
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  getRecDataBySite,
} from "api/rec";

import {
  COLORS,
  H4,
  Label14Medium,
  Loading,
} from "components";

import { useGetAllowedValues, useSiteById } from "hooks";


import { EsgEditIcon, EsgLeftArrow, EsgRecGreenIcon, EsgRecIcon, EsgRECPendingIcon, EsgRECUploadedIcon, PlusIcon } from "assets";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { RecModal } from "./components/site-editor-form/components/RecModal";
import moment from "moment";

export const RecUpload: React.FunctionComponent = () => {

  // ---- Hooks ----
  dayjs.extend(timezone);
  const navigate = useNavigate();

  // ---- Const-----
  const { collectorId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const siteId = query.get('s');

  // ---- State ----
  const [isRecModalOpen, setRecModalOpen] = useState(false);
  const [editRECOpen, setEditRECOpen] = useState(false);
  const [editRECData, setEditRECData] = useState(null);

  // ---- API's ----
  const { data: site } = useSiteById(siteId as any, true);
  const { data: recData, mutateAsync: getRecDocument, isLoading } = useMutation(getRecDataBySite);
  const { data: allowedValues } = useGetAllowedValues();
  const recsPreFilledDetails = site?.collectors?.filter((t) => t?.id === parseInt(collectorId!))

  // ---- Use Effects ----
  useEffect(() => {
    getRecDocument(collectorId)
  }, [collectorId])


  // ----- Table Columns ----
  const recDetailColumn = useMemo(
    () => [
      {
        accessorKey: "utilityProvider",
        header: "Utility Provider",
      },
      {
        accessorKey: "utilityTypeName",
        header: "Utility Type",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorKey: "renewableEnergy",
        header: "Coverage Percentage",
      },
      {
        accessorKey: "startDate",
        header: "REC Start Date",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }} component="span">
                {moment(value).format("DD-MM-YYYY")}
              </Box>
            </Box>
          );
        },
      },
      {
        accessorKey: "endDate",
        header: "REC End Date",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }} component="span">
                {moment(value).format("DD-MM-YYYY")}
              </Box>
            </Box>
          );
        },
      },
      {
        accessorKey: "siteName",
        header: "Sites Applied",
      },
    ],
    [],
  ) as MRT_ColumnDef<any>[];


  // ---- Page Functions ----
  const handleCloseRecModal = () => {
    setRecModalOpen(false);
    setEditRECOpen(false);
    setEditRECData(null);
    getRecDocument(collectorId)
  }

  const handleEditCollector = (rowData: any) => {
    setEditRECOpen(true);
    setEditRECData(rowData);
  };

  if (isLoading) return <Loading />;
  return (
    <Box sx={{ mx: 3 }}>
      {/* Multiple Rec Upload Table */}
      <H4 mt={4} mb={2}>
        REC Details
      </H4>
      <Divider sx={{ mt: 2, mb: 2, backgroundColor: '#054254' }} />
      <br />
      <ButtonBase sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:active': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)', // active state effect
        },
      }}
        onClick={() => navigate(-1)}
      >
        <EsgLeftArrow />
        <Label14Medium>Back to Utility Providers</Label14Medium>
      </ButtonBase>
      <Button
        sx={{ my: 2, borderRadius: '8px' }}
        variant="contained"
        onClick={() => setRecModalOpen(true)}
        startIcon={<PlusIcon />}
      >
        Create New REC
      </Button>
      <br />
      <Box>
        {recData && <MaterialReactTable
          muiTableContainerProps={{
            sx: {
              "& tbody tr td:first-of-type": {
                paddingLeft: "20px",
              },
              "& tbody tr:first-of-type td > p": {
                display: "flex",
                width: "100%",
                justifyContent: "center",
              },
              border: "1px solid #D9D9D9", borderRadius: '10px',
              minWidth: '100%',
              width: '100%',
              maxWidth: '100%',
              '@media (min-width: 600px)': {
                width: '100%',
              },
              '@media (min-width: 960px)': {
                width: '1200px',
              },
              '@media (min-width: 1280px)': {
                width: '1200px',
              },
              '@media (min-width: 1440px)': {
                width: '1200px',
              },
              '@media (min-width: 1680px)': {
                width: '1400px',
              },
              '@media (min-width: 2400px)': {
                width: '100%',
              },
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: 'none'
            }
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiBottomToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: '30px',
              alignItems: 'center',
              color: COLORS.Romance,
              fontWeight: '600',
              fontSize: '14px',
              fontStyle: "Inter",
              '& .MuiBadge-root': {
                display: 'none',
              },
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              height: '40px',
              backgroundColor: '#F8FAFC',
              borderBottomColor: '#F8FAFC',
              textAlign: 'center',
              alignItems: 'center'

            }
          }}
          muiTableBodyRowProps={{
            sx: {
              height: '40px',
              backgroundColor: 'white',
            }
          }}
          columns={recDetailColumn}
          data={recData?.data as any}
          enablePagination={false}
          enableDensityToggle={false}
          enableRowActions
          initialState={{
            density: "compact",
          }}
          renderRowActions={({ row }) => {
            const recId = row?.original?.recId
            return (
              <Box sx={{
                display: "flex",
                borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', paddingRight: '5px'
              }}>
                {row?.original?.isRecsUploaded ? <Tooltip arrow placement="top" title="REC is Uploaded">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={() => navigate(`/recDetail/${recId}`)}>
                    <EsgRECUploadedIcon />
                  </IconButton>
                </Tooltip> : <Tooltip arrow placement="top" title="Add REC">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={() => navigate(`/recDetail/${recId}`)}>
                    <EsgRECPendingIcon />
                    {row?.original?.id}

                  </IconButton>
                </Tooltip>}
                <Tooltip arrow placement="top" title="Edit">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={() => handleEditCollector(row?.original)}>
                    <EsgEditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )
          }}
        />}
      </Box>
      <br />

      <RecModal
        isOpen={
          isRecModalOpen || editRECOpen
        }
        editREC={editRECData}
        onClose={handleCloseRecModal}
        columns={recDetailColumn}
        allowedValues={allowedValues}
        site={siteId as any}
        recData={recsPreFilledDetails}
      />
    </Box>
  );
};