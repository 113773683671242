import { useCallback } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

import { UserDTO } from "api";

export const USER_ROLES = {
  ADMIN: "admin",
  AUDITOR: "auditor",
  SITE_MANAGER: "site_manager",
  ENABLED: "enabled",
} as const;

export const USER_ROLES_DROPDOWN = {
  ADMIN: "admin",
  AUDITOR: "auditor",
  SITE_MANAGER: "site_manager",
} as const;

export const USER_ROLES_NAMES = {
  [USER_ROLES.ADMIN]: "Sustainability manager",
  [USER_ROLES.AUDITOR]: "Auditor",
  [USER_ROLES.SITE_MANAGER]: "Data provider",
  [USER_ROLES.ENABLED]: "Enabled",
};

export const USER_ROLES_NAMES_DROPDOWN = {
  [USER_ROLES.ADMIN]: "Sustainability manager",
  [USER_ROLES.AUDITOR]: "Auditor",
  [USER_ROLES.SITE_MANAGER]: "Data provider",
};

export const USER_ROLES_NAME_TO_VALUE = Object.fromEntries(
  Object.entries(USER_ROLES_NAMES).map(([key, value]) => [value, key]),
);

export const USER_ROLE_COLOR = {
  [USER_ROLES.ADMIN]: "#E7C69A", // Sustainability manager
  [USER_ROLES.AUDITOR]: "#86B2A3", // auditor
  [USER_ROLES.SITE_MANAGER]: "#AAF0D5", // data provider
  [USER_ROLES.ENABLED]: "#C8E1F7", // Disabled
};

export const getUserRoleObject = (role: UserRole) => {
  if (role === USER_ROLES.ADMIN) {
    return {
      admin: true,
      auditor: false,
      enabled: true,
    };
  }

  if (role === USER_ROLES.AUDITOR) {
    return {
      admin: false,
      auditor: true,
      enabled: true,
    };
  }

  if (role === USER_ROLES.ENABLED) {
    return {
      enabled: false,
      admin: false,
      auditor: false,
    };
  }

  return {
    admin: false,
    auditor: false,
  };
};

export type UserRole = (typeof USER_ROLES)[keyof typeof USER_ROLES];

interface UnauthenticatedUser {
  isAuthenticated: false;
}

export interface AuthenticatedUser extends UserDTO {
  isAuthenticated: true;
}

export type UserState = UnauthenticatedUser | AuthenticatedUser;

const DEFAULT_USER_STATE: UnauthenticatedUser = {
  isAuthenticated: false,
};

export const userState = atom<UserState>({
  key: "user",
  default: DEFAULT_USER_STATE,
});

export const isUserAuthenticatedState = selector({
  key: "isUserAuthenticated",
  get: ({ get }) => get(userState).isAuthenticated,
});

export const useSetDefaultUser = () => {
  const setUserState = useSetRecoilState(userState);
  return useCallback(() => setUserState(DEFAULT_USER_STATE), [setUserState]);
};

export const useSetUser = () => {
  const setUserState = useSetRecoilState(userState);

  return useCallback(
    (user?: UserDTO) => {
      if (!user) {
        setUserState(DEFAULT_USER_STATE);
        throw Error("Can not set authenticated user.");
      }

      setUserState({
        isAuthenticated: true,
        ...user,
      });
    },
    [setUserState],
  );
};

export const useAuthenticatedUserState = (): AuthenticatedUser => {
  const user = useRecoilValue(userState);

  if (!user.isAuthenticated) {
    throw new Error(`The user must be authenticated!`);
  }

  return user;
};

export const useUserRole = () => {
  const { admin, auditor } = useAuthenticatedUserState();
  const role = getUserRole(admin, auditor);
  const isRoleIn = useCallback(
    (roles: UserRole[]) => roles.includes(role),
    [role],
  );

  return {
    isAdmin: admin,
    isAuditor: auditor,
    isDataProvider: !admin && !auditor,
    role,
    isRoleIn,
  };
};

export const getUserRole = (isAdmin: boolean, isAuditor: boolean) => {
  if (isAdmin) {
    return USER_ROLES.ADMIN;
  }

  if (isAuditor) {
    return USER_ROLES.AUDITOR;
  }

  return USER_ROLES.SITE_MANAGER;
};
