import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";

import { MarginWrapper } from "components";

import { useEmissionFactors, useScientificUnit } from "hooks";

import { DashboardTabs } from "pages/authenticated/dashboards/components/dashboardTabs";

import { useNewBreadcrumb } from "recoils";

import { EMISSION_FACTORS_PAGE } from "./consts";
import { Currency } from "./currency";
import { EmissionFactors } from "./emission-factors";
import { ScientificUnit } from "./scientific-unit";

export const EmissionFactorsPage = () => {
  useNewBreadcrumb(EMISSION_FACTORS_PAGE.TITLE);
  const [value, setValue] = React.useState("");
  const { data: references } = useEmissionFactors();
  const { data: scientificunitandcurrency } = useScientificUnit();
  const tableRows = references || [];
  const scientificUnit = scientificunitandcurrency?.scientificUnit;
  const currency = scientificunitandcurrency?.currency;

  const CsvMatchedData = () => {
    let selectedData: string | Data | (() => string | Data) = [];

    if (value === "Emission Factors") {
      selectedData = tableRows?.map((item: any) => ({
        "Emission Factor Type": item?.emissionFactorType,
        "Utility TypeName": item.utilityTypeName,
        "Utility SubType": item.utilitySubtype,
        "Utility Type System Unit": item.measurementUnit,
        "Valid From": item.validFrom
          ? moment(item.validFrom).utc().format("DD-MM-YYYY")
          : "",
        "Valid To": item.validTo
          ? moment(item.validTo).utc().format("DD-MM-YYYY")
          : "",
        Region: item.region,
        "GWP Multipliers": item.gwpMultipliers,
        "GWP Source": item.gwpSource,
        "CO2eq EF": item.co2ConversionRatio,
        "CO2eq EF Unit": item.co2ConversionUnit,
        "CO2 EF": item.co2EmissionFactorRatio,
        "CO2 EF Unit": item.co2EmissionFactorUnit,
        "CH4 Fossil EF": item.ch4FossilEmissionFactorRatio,
        "CH4 EF Unit": item.ch4FossilEmissionFactorUnit,
        "CH4 Non-Fossil EF": item.ch4NonFossilEmissionFactorRatio,
        "CH4 Non-Fossil EF Unit": item.ch4NonFossilEmissionFactorUnit,
        "N2O EF": item.n2oEmissionFactorRatio,
        "N2O EF Unit": item.n2oEmissionFactorUnit,
        Source: item.source,
        " Source Update Date": item.sourceUpdateDate,
        "ESG Flo Access Date": item.esgFloAccessDate
          ? moment(item.esgFloAccessDate).utc().format("DD-MM-YYYY")
          : "",
        "Source Link": item.sourceLink,
      }));
    } else if (value === "Currency") {
      const parsedData = currency?.map((str: string, index: any) => {
        try {
          return JSON.parse(str);
        } catch (error) {
          console.error(`Error parsing string at index ${index}:`, error);
          return null;
        }
      });

      selectedData = parsedData?.map((item: any) => ({
        From: item.fromCurrency,
        To: item.toCurrency,
        Conversion: item.conversionValue,
        "Last Updated": item.updatedDate
          ? moment(item.updatedDate).utc().format("DD-MM-YYYY")
          : "",
      }));
    } else {
      const parsedData = scientificUnit?.map((str: string, index: any) => {
        try {
          return JSON.parse(str);
        } catch (error) {
          console.error(`Error parsing string at index ${index}:`, error);
          return null;
        }
      });

      selectedData = parsedData?.map((item: any) => ({
        UtilityType: item.utilityType,
        UtilitySubType: item.utilitySubtype,
        From: item.toUnit,
        To: item.fromUnit,
        Conversion: item.conversionValue,
        "Last Updated": item.updatedDate
          ? moment(item.updatedDate).utc().format("DD-MM-YYYY")
          : "",
      }));
    }
    return selectedData;
  };

  const leagalBtnStates = {
    LEGAL: "References",
  };

  const { LEGAL } = leagalBtnStates;
  const tabs: any = {
    [LEGAL]: [
      {
        label: "Emissions Factors",
        content: <EmissionFactors ReferencesData={tableRows} />,
      },
      {
        label: "Currency",
        content: <Currency ReferencesData={currency} />,
      },
      {
        label: "Scientific Unit",
        content: <ScientificUnit ReferencesData={scientificUnit} />,
      },
    ],
  };

  const handleTabChange = (tabName: any) => {
    if (tabName === 0) {
      setValue("Emission Factors");
    } else if (tabName === 1) {
      setValue("Currency");
    } else {
      setValue("Scientific Unit");
    }
  };

  return (
    <MarginWrapper>
      <Box mb={4}>
        <CSVLink
          style={{ textDecoration: "none" }}
          data={CsvMatchedData() ?? []}
          filename={`${value.replace(" ", "-")}.csv`}
        >
          <Button variant="contained" endIcon={<SendIcon />}>
            Export CSV
          </Button>
        </CSVLink>
      </Box>

      <DashboardTabs
        intensity
        refrences
        tabs={tabs[LEGAL]}
        selectedBtnOption={LEGAL}
        onTabChange={handleTabChange}
      />
    </MarginWrapper>
  );
};
