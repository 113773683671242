import { buildQueryParams, getResData, httpClient } from "utils";


export const SITES_EMISSIONS_API_PATHS = {
  SITES_EMISSIONS: (query: SitesEmissionsQuery) =>
    `/aggregations?${buildQueryParams(query)}`,

    SITES_EMISSIONS_VIEW: (query: SitesEmissionsQuery) =>
    `/aggregations/viewasof?${buildQueryParams(query)}`,

  SCOP3_SITES_EMISSIONS: (query: SitesScope3EmissionsQuery) =>
    `/supplies/emission?${buildQueryParams(query)}`,

  AUDIT_LOGS: `/audit-log/search`,
};

export const SITES_EMISSIONS_TABLE_API = {
  SITES_EMISSIONS: (query: DetailSitesEmissionsQuery) =>
    `/aggregations/billdetailsbysiteutility?${buildQueryParams(query)}`,
};

interface UtilityTypeUsage {
  unit: string;
  usage: number;
  utilityType: string;
}

interface SiteAggregation {
  co2Emission: number;
  emissionM2M: number;
  emissionY2Y: number;
  site: string;
  siteId: number;
  utilityTypes: UtilityTypeAggregation[];
  createDate?:any;
}

export interface UtilityTypeAggregation {
  co2Emission: number;
  unit: string;
  usage: number;
  usageM2M: number;
  usageY2Y: number;
  utilityType: string;
  utilityTypeId: number;
}

export interface UtilityModalTypeAggregation {
  accountNumber: number;
  amount: number;
  billGuid: string;
  co2Emission: number;
  currency: string;
  endDate: string;
  id: string;
  measuredUsage: number;
  measuredUsageUnit: string;
  meterNumber: number;
  noOfComments: number;
  period: string;
  providerName: string;
  startDate: string;
  status: string;
  uploader: string;
}

export interface SitesEmissionsDetailsDTO {
  BillDetailsBySiteUtility: UtilityModalTypeAggregation[];
}
export interface SitesEmissionsDTO {
  selectedSites: number;
  siteAggregations: SiteAggregation[];
  totalCo2Emission: number;
  totalCh4OnlyEmission: number;
  totalCo2OnlyEmission: number;
  totalN2oOnlyEmission: number;
  totalScope1Emission: number;
  totalScope2Emission: number;
  totalSites: number;
  totalIntensityProductionCo2Emission: number;
  totalIntensityRevenueCo2Emission: number;
  totalIntensityAreaCo2Emission: number;
  totalMarketIntensityAreaCo2Emission: number;
  totalMarketIntensityProductionCo2Emission: number;
  totalMarketIntensityRevenueCo2Emission: number;
  intensityAreaUnit: string;
  intensityProductionUnit: string;
  intensityRevenueUnit: string;
  utilityTypesUsage: UtilityTypeUsage[];
}

export type SitesEmissionsQuery = {
  endDate: string;
  startDate: string;
  viewType?: string;
  isDisaggregated?: any;
  siteIds?: number[];
  businessUnits?: string[],
  utilityTypeIds?: number[];
};

export type SitesEmissionsQueryview = {
  endDate: string;
  startDate: string;
  viewType?: string;
  isDisaggregated?: any;
  siteIds?: number[];
  utilityTypeIds?: number[];
  viewAsOfDate?:string;
};

export type SitesScope3EmissionsQuery = {
  end_date: string;
  start_date: string;
};

export type DetailSitesEmissionsQuery = {
  startDate: string;
  endDate: string;
  siteId: string;
  utilityTypeId: string;
};

export const getSitesEmissions = (
  query: SitesEmissionsQuery,
): Promise<SitesEmissionsDTO> =>
  httpClient
    .get<SitesEmissionsDTO>(SITES_EMISSIONS_API_PATHS.SITES_EMISSIONS(query))
    .then(getResData);

export const getSitesEmissionsview = (
  query: SitesEmissionsQuery,
): Promise<SitesEmissionsDTO> =>
  httpClient
    .get<SitesEmissionsDTO>(SITES_EMISSIONS_API_PATHS.SITES_EMISSIONS_VIEW(query))
    .then(getResData);


export const getAuditLogsData = (
  body: any,
): Promise<any> =>
  httpClient
    .post<any>(SITES_EMISSIONS_API_PATHS.AUDIT_LOGS, body)
    .then(getResData);

        

export const getScop3SitesEmissions = (
  query: SitesScope3EmissionsQuery,
): Promise<any> =>
  httpClient
    .get<any>(SITES_EMISSIONS_API_PATHS.SCOP3_SITES_EMISSIONS(query))
    .then(getResData);

export const getDetailSitesEmissions = (
  query: DetailSitesEmissionsQuery,
): Promise<SitesEmissionsDetailsDTO> =>
  httpClient
    .get<SitesEmissionsDetailsDTO>(
      SITES_EMISSIONS_TABLE_API.SITES_EMISSIONS(query),
    )
    .then(getResData);