import React, { FunctionComponent, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { CloseButton, COLORS, H5Medium, Label12SemiBold, TYPOGRAPHY_COMMON_STYLES } from "components";
import { useMutation } from "@tanstack/react-query";
import { updateUtilityFormat } from "api";
import { useNotify } from "hooks";

const ValueFormatEditor: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  collectorId: number;
  valueFormatName: string;
}> = ({ isOpen, onClose, collectorId, valueFormatName }) => {

  const notify = useNotify();

  // ---- API ----
  const useUpdateUtilityFormat = ({ onSuccess, onError }: any) =>
    useMutation({
      mutationFn: ({ id, body }: { id: any; body: any }) =>
        updateUtilityFormat(id, body),
      onSuccess,
      onError,
    });

  const { mutate: updateMutate} = useUpdateUtilityFormat({
    onSuccess: () => {
      notify.success('Value format updated successfully');
    },
    onError: (error: any) => {
      console.error('Update failed:', error);
    },
  });

  const [numberFormat, setNumberFormat] = useState("");


  const blockOption = [
    {
      id: 1,
      name: 'XXXXXXX'
    },
    {
      id: 2,
      name: 'XXXXXXX,XX'
    },
    {
      id: 3,
      name: 'X XXX XXX,XX'
    },
    {
      id: 4,
      name: 'X.XXX.XXX,XX'
    },
    {
      id: 5,
      name: `X'XXX'XXX.XX`
    },
    {
      id: 6,
      name: 'X,XXX,XXX.XX'
    },
    {
      id: 7,
      name: 'XX,XX,XXX.XX'
    },
  ]


  const handleSave = () => {
    if (collectorId) {
      if (valueFormatName === "usageFormat") {
        updateMutate({
          id: collectorId,
          body: { usageFormat: numberFormat }
        });
      }
      if (valueFormatName === "costFormat") {
        updateMutate({
          id: collectorId,
          body: { costFormat: numberFormat }
        });
      }
      onClose();
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          color: "#054254",
          fontWeight: "600",
          borderBottom: "1px solid #D9D9D9",
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CloseButton onClick={onClose} variant="text" />
      </DialogTitle>
      <DialogContent sx={{ padding: "24px" }}>
        <Box display="flex" flexDirection="column" gap="12px">
          <H5Medium sx={{ mt: 2 }}>Value Format Editor</H5Medium>
          {/* Blocks Section */}
          <Box display="flex" gap="12px" alignItems="center" mt={2}>
            {/* First Block */}
            <Box>
              <Label12SemiBold>Value Format</Label12SemiBold>
              <FormControl
                sx={{
                  minWidth: 140,
                  height: 36,
                  borderRadius: "8px",
                  border: "1px solid #054254",
                  overflow: "hidden",
                  fontFamily: "Inter",
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    padding: 0,
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiSelect-select": {
                    padding: "8px 12px",
                    fontFamily: "Inter",
                    fontSize: '14px'
                  },
                }}
              >
                <Select
                  value={numberFormat}
                  onChange={(e) => setNumberFormat(e.target.value)}
                  displayEmpty
                >

                  {blockOption?.map((t) =>  (
                      <MenuItem
                        sx={{
                          fontWeight:
                            TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                          fontSize: 14,
                          lineHeight: "18px",
                          color: "#90959E",
                          "&:hover": {
                            backgroundColor: "#F6F8FA",
                            borderRadius: 5,
                            color: COLORS.Romance,
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#F6F8FA",
                            borderRadius: 5,
                            color: COLORS.Romance,
                          },
                        }}
                        value={t?.name}>{t?.name}</MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* Preview Section */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ mt: 4 }}>
              <Label12SemiBold>
                New Format
              </Label12SemiBold>
              <H5Medium sx={{ color: COLORS.Romance }}>
                {numberFormat}
              </H5Medium>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Button
                sx={{
                  color: "#6B7280",
                  fontWeight: "500",
                  width: '140px',
                  borderRadius: '8px'
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '140px',
                  borderRadius: '8px',
                  backgroundColor: "#054254",
                  color: "#fff",
                  fontWeight: "500",
                  "&:hover": { backgroundColor: "#033840" },
                }}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ValueFormatEditor;
